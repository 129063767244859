<template>
    <section class="bg-white py-2 mt-2">
      <div class="lg:flex justify-between p-6 items-center">
        <div class="">
          <h1 class="text-lg text-blue-800 font-bold">Empleados</h1>
        </div>
        <div class="lg:flex h-auto">
          <Button v-if="$can('pharmasan.recursos-humanos.empleados.access')" @click="$router.push({name: 'pharmasan.recursos-humanos.empleados.crear'})" icon="pi pi-user-plus" class="mr-2 mb-2 w-full" :label="$route.params.id ? 'Editar empleado' : 'Crear empleado'" />
          <div class="mb-2 flex">
            <Dropdown class="mr-2" :showClear="true" v-model="exportacionSeleccionada" :options="tiposExportacion" optionLabel="name" optionValue="id" placeholder="Exportar" />
            <Button @click="exportarEmpleados" v-if="exportacionSeleccionada" class="mr-2 px-2 p-button-success" icon="pi pi-download" />
          </div>
          <!-- <Dropdown
            v-model="filterNameSelected"
            @change="filtrarTabla"
            :options="listadoEmpleados"
            class="mr-4 mb-2 w-full"
            optionLabel="full_name"
            placeholder="Buscar por nombre"
            :showClear="true"
            :filter="true"/> -->
        </div>
      </div>
      <TablaEmpleados />
    </section>
  </template>
  <script>
    import { onMounted, defineAsyncComponent, ref } from 'vue'
    import EmpleadosService from '../../services/empleados.service'
    import empleadosStore from '../../stores/empleados.store'
    // import SedesService from '../../services/sedes.service'
    // import sedesStore from '../../stores/sedes.store'
    export default {
    name: 'menuEmpleados',
    components: {
      TablaEmpleados: defineAsyncComponent(
        () => import('./pages/listar/TablaEmpleados.vue')
      )
    },
    setup () {
      const _EmpleadosService = ref(new EmpleadosService())
      // const _SedesService = ref(new SedesService())
      const listadoEmpleados = ref([])
      const filterNameSelected = ref('')
      const exportacionSeleccionada = ref(0)
      const tiposExportacion = ref(
        [{
          name: 'Empleados activos',
          id: 1
        },
        {
          name: 'Empleados inactivos',
          id: 2
        },
        {
          name: 'Todos los empleados',
          id: 3
        }]
      )
      const exportarEmpleados = () => {
        exportacionSeleccionada.value = 0
      }
      const filtrarTabla = () => {
        empleadosStore.dispatch('filtroPorNombreTabla', filterNameSelected.value ? filterNameSelected.value.full_name : '')
      }
      const getEmpleados = () => {
        _EmpleadosService.value.getAll().then(({ data }) => {
          listadoEmpleados.value = []
          for (const i of data) {
            listadoEmpleados.value.push(i.persona)
          }
        })
      }
      const obtenerSedes = () => {
        console.log('entro aaqui')
      }
      onMounted(() => {
        obtenerSedes()
        getEmpleados()
      })

      return {
        listadoEmpleados,
        filterNameSelected,
        filtrarTabla,
        tiposExportacion,
        exportacionSeleccionada,
        exportarEmpleados
      }
    }
  }
  </script>

  <style scoped>
    ::v-deep(.p-selectbutton .p-button.p-highlight){
       background: transparent;
       border-color: transparent;
       color: #1C3FAA;
       border-bottom: 2px solid #1C3FAA;
       font-weight: 600;
       font: normal normal bold 17px/20px Roboto;
    }
    ::v-deep(.p-selectbutton .p-button.p-highlight:hover){
       background: transparent;
       border-color: transparent;
       color: #1C3FAA;
       border-bottom: 2px solid #1C3FAA;
       font-weight: 600;
       font: normal normal bold 17px/20px Roboto;
    }
    ::v-deep(.p-selectbutton .p-button){
       background: transparent;
       border-color: transparent;
       color: var(--unnamed-color-7f7e81);
       font: normal normal normal 14px/16px Roboto;
     }
    ::v-deep(.p-selectbutton .p-button:hover){
       background: transparent;
       border-color: transparent;
       color: var(--unnamed-color-7f7e81);
       font: normal normal normal 14px/16px Roboto;
    }

  </style>
